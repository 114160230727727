export default {
  methods: {
    mixPanelUserLogin(response) {
      console.log('mixPanelUserLogin', response)
      // eslint-disable-next-line no-undef
      mixpanel.identify(response.loggedUserId);
      // eslint-disable-next-line no-undef
      mixpanel.people.set_once({
          "First Login": new Date().toISOString(),
      });

      // eslint-disable-next-line no-undef
      mixpanel.track("Login", { "Login Method": "Email", "Login Platform": this.$getPlatform(), "Login client": "Webapp" });

      // eslint-disable-next-line no-undef
      mixpanel.register({
          "email": response.email,
          "name": response.name,
      });

      // eslint-disable-next-line no-undef
      mixpanel.people.set({ "$email": response.email, "$name": response.name });
      // eslint-disable-next-line no-undef
      mixpanel.identify(response.loggedUserId);

      //desactivat pk ho hem posat al servidor.
      // eslint-disable-next-line no-undef
      mixpanel.people.increment('Total logins');
      // eslint-disable-next-line no-undef
      mixpanel.people.set({ "Last Login": new Date().toISOString() });


      // eslint-disable-next-line no-undef
      mixpanel.people.set_once({
          '# of Searches': 0,
          'Total share': 0,
          'Total Add': 0
      });
    }
  }
}