<template>
    <no-ssr>
      <div class="bg-cercles">
        <div class="bb-login">
          <div class="bb-container">
            <div class="bb-login__container">
              <div class="login-box">
                <h1 class="login-box__title">{{ $t("pages.invitation") }}</h1>
  
                <Form
                    class="form"
                    :operation="operation"
                    :valid="valid"
                    :error="error"
                >
                  <template>
                    <div class="login-box__inputs" v-if="!isConfirmed">
                      <div class="bb-form-item">
                        <label for="email" class="bb-form-label">{{ $t("login.yourEmail") }}</label>
                        <FormInput
                            class="bb-form"
                            v-model="email"
                            name="email"
                            type="email"
                            disabled="disabled"
                        />
                      </div>
                      <div class="bb-form-item">
                        <label for="email" class="bb-form-label">{{ $t("login.password") }}</label>
                        <FormInput
                            class="bb-form"
                            v-model="password"
                            name="password"
                            type="password"
                            :invalid="passwordEmpty"
                            :placeholder="$t('login.password')"
                        />
                      </div>
                      <div class="bb-form-item">
                        <label for="Contrase-a" class="bb-form-label"
                        >{{ $t("login.recover.confirmPassword") }}</label
                        >
                        <FormInput
                            class="bb-form"
                            name="password"
                            type="password"
                            v-model="password2"
                            :invalid="password2Empty"
                            :placeholder="$t('login.recover.confirmPassword')"
                            :style="stylePwdComparation"
                        />
                      </div>
                      <label class="w-checkbox checkbox-field">
                        <input
                            type="checkbox"
                            id="Privacidad"
                            name="Privacidad"
                            data-name="Privacidad"
                            required
                            class="w-checkbox-input"
                            v-model="privacity"
                        />
                        <label style="display: inline;" for="Privacidad" class="checkbox-label w-form-label">
                        &nbsp;{{ $t("signup.acceptTCpart1") }}
                        <router-link to="/politica-privacidad" style="text-decoration: underline;" target="_blank"
                            >{{ $t("signup.acceptTCpart2") }}</router-link
                        >.
                        </label>
                        </label>
                      <div style="margin-top: 20px">
                        <p>
                        <span v-if="!validLength">&#10005; </span>
                        <span style="color: green;" v-else><strong>&#10003; </strong></span>
                        {{ $t("login.recover.minLength") }}</p>
                      <p>
                        <span v-if="!validComplexity">&#10005; </span>
                        <span v-else style="color: green;"><strong>&#10003; </strong></span>
                        {{ $t("login.recover.complexity") }}</p>
                      </div>
                    </div>
                    <div v-if="isConfirmed">
                        <p>{{ $t("login.recover.success") }}</p>
                        <!-- You can add additional content or instructions here -->
                    </div>
                  </template>
  
                  <template slot="actions">
                    <div class="login-box__actions">
                      <input
                        v-if="!isConfirmed"
                          type="submit"
                          :disabled="!valid"
                          :value="$t('login.logIn')"
                          data-wait="Un segundo..."
                          class="button button--primary button--block button--lg"
                      />
                      <input 
                        v-else 
                        class="button button--primary button--block button--lg" 
                        :value="$t('login.recover.goToLogin')" 
                        data-wait="Un segundo..." 
                        @click="$router.push({ name: 'login' })">
                    </div>
  
                    <!-- <div class="login-box__recovery">
                      ¿Olvidaste tu contraseña?
                      <router-link to="/recoverpwd">
                        Solicítala de nuevo</router-link
                      >
                    </div> -->
                  </template>
                </Form>
              </div>
  
              <!-- <div class="login-account">
                <div class="login-account__picture">
                  <img src="../../assets/img/logos/favicon.svg" alt="" />
                </div>
                <div class="login-account__content">
                  <span>¿Todavía no tienes lista?</span>
                  <router-link to="/signup">Créala ahora</router-link>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </no-ssr>
  </template>
  
  <script>
  import NoSSR from "vue-no-ssr";
  import axios from "axios";
  import loginMixin from "../mixins/login.js";
  export default {
    name: "invitacion",
    mixins: [loginMixin],
    components: {
      "no-ssr": NoSSR,
    },
    metaInfo() {
      return {
        title: this.$t("pages.invitationMetaTitle") + " | HelloBB",
        meta: [{name: "description", content: this.$t("pages.invitationMetaDescription")}],
        link: [{ rel: "Invitacion", href: "https://www.hellobb.net/invitacion" }],
      };
    },
    data() {
      return {
        password: "",
        password2: "",
        email: "",
        loading: false,
        emailEmpty: false,
        passwordEmpty: false,
        password2Empty: false,
        nameEmpty: false,
        error: null,
        isConfirmed: false,
        privacity: false,
        userId: 0,
      };
    },
    computed: {
      valid() {
        return (this.password == this.password2) && (this.password != "") && (this.password2 != "") && this.validLength && this.validComplexity && this.privacity; 
      },
      validLength() {
        return (this.password.length >= 8);
      },
      validComplexity() {
        return ((this.password.match(/[a-z]/g) || this.password.match(/[A-Z]/g)) && this.password.match(/[0-9]/g));
      },
      stylePwdComparation() {
        //if password not have 4 chars don't show the red border
        if(this.password.length < 4) {
          return "";
        }
        if(this.password != this.password2) {
          return "border-color: red;";
        } else {
          return "";
        }
      }
    },
    methods: {
      async operation() {
        const baseURL = process.env.API;
        try {
          let res = await axios.put(baseURL + "/user/collaborator/invitation/accept", {
                email: this.email,
                password: this.password,
                userId: this.userId,
              }
          );
          if (res.status == 200) {
            //TBD
          }
        } catch (error) {
          this.error = this.$t("generic.errorNoSave");
        }

        await this.login("mi-lista");
      },
      validations() {
        return (this.password == this.password2) && (this.password != "") && (this.password2 != "");
      },
    },
    async mounted() {
      const baseURL = process.env.API;
      let token = this.$route.query.token;
      try {
        let res = await axios.get(baseURL + "/user/collaborator/invitation/" + token);
        if (res.status == 200) {
          this.email = res.data.email;
          this.userId = res.data.userId;
          window.mixpanel.identify(this.userId);
          window.mixpanel.track("Col·laborador visualiza la invitació", {
            "Email": this.email
          });
        } else if (res.status == 201) {

          this.error = this.$t("pages.invitationAlreadyAccepted");
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 4000);
        } else {
          this.error = this.$t("generic.errorNoSave");
        }
      } catch (error) {
        this.error = this.$t("generic.errorNoSave");
      }
    },
  };
  </script>